import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

import PageLayout from '@components/layout/PageLayout';
import PageHero from '@components/layout/PageHero';
import * as styles from '@css/modules/crisis.module.scss';
import { useMPEvent } from '@util/mixpanel';
import { GAEvent } from '@src/util/analytics/GAEvent';
import { NodeLocale } from '@src/interfaces/NodeLocale';
import { RichTextField } from '@src/interfaces/RichTextField';
import { Image } from '@src/interfaces/Image';

type PersonType = 'adult' | 'youth' | 'frontline' | 'indigenous' | 'other';

interface CrisisPageProps {
  data: {
    contentfulCrisisPage: CrisisPageData;
  };
  pageContext: {
    pageName: string;
  };
}

export default function Crisis({ data, pageContext }: CrisisPageProps) {
  const {
    node_locale: nodeLocale,
    pageTitle,
    header,
    subTitle: { subTitle },
    pageSummary,
    crisisOptionsHeader,
    crisisOptions,
    crisisOptionsExtraHeader,
    crisisOptionsExtra,
    alert: { alert },
  } = data.contentfulCrisisPage;
  const { trackPageLoad, trackPageInteraction } = useMPEvent();
  const pageLoadEventData = {
    language: nodeLocale,
    url: `/${nodeLocale}/crisis`,
    url_name: 'crisis',
  };
  useEffect(() => {
    trackPageLoad(pageLoadEventData);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function clickCrisisSupport(personType: PersonType) {
    trackPageInteraction({
      ...pageLoadEventData,
      element: 'crisis support line',
      action: 'click',
      value: personType,
    });
    GAEvent.crisisPage.clickCrisisSupport(personType);
  }

  return (
    // TODO: Fix this once we’ve got proper types on PageLayout
    // @ts-expect-error
    <PageLayout
      node_locale={nodeLocale}
      page={pageContext.pageName}
      pageTitle={pageTitle}
    >
      <PageHero data={{ header, subTitle }} bigSubTitle={true} />

      <Container>
        <div className={styles.pageSummary}>{renderRichText(pageSummary)}</div>
      </Container>

      <Container className="mt-5 mb-3">
        <h2 className={styles.crisisHeader}>{crisisOptionsHeader}</h2>

        <Row>
          {crisisOptions
            .slice()
            .sort((a, b) => a.index - b.index)
            .map((crisisOption) => (
              <CrisisOptionCard
                crisisOption={crisisOption}
                clickHandler={clickCrisisSupport}
              />
            ))}
        </Row>
      </Container>

      <Container className="mt-5 mb-3">
        <h2 className={styles.crisisHeader}>{crisisOptionsExtraHeader}</h2>

        <Row>
          {crisisOptionsExtra
            .slice()
            .sort((a, b) => a.index - b.index)
            .map((crisisOption) => (
              <CrisisOptionCard
                crisisOption={crisisOption}
                clickHandler={clickCrisisSupport}
              />
            ))}
        </Row>
      </Container>

      <Container>
        <Alert className="text-white bg-secondary p-5 mb-5">{alert}</Alert>
      </Container>
    </PageLayout>
  );
}

interface CrisisOptionCardProps {
  crisisOption: CrisisOptionData;
  clickHandler: (trackingLabel: PersonType) => void;
}

const CrisisOptionCard = ({
  crisisOption,
  clickHandler,
}: CrisisOptionCardProps) => {
  return (
    <Col xs={12} md={6} className={styles.crisisCardCol}>
      <a
        className={styles.crisisCardWrapper}
        href={crisisOption.linkUrl}
        onClick={() => clickHandler(crisisOption.trackingLabel)}
      >
        <Card className="h-100">
          <Card.Body className={styles.crisisCard}>
            <h3 className={styles.crisisCardHeader}>{crisisOption.target}</h3>
            <div className={styles.crisisCardContent}>
              <GatsbyImage
                image={crisisOption.icon.gatsbyImageData}
                alt={crisisOption.icon.title}
                className={styles.icon}
              />
              <p className={styles.crisisCardCopy}>
                {crisisOption.action
                  ? renderRichText(crisisOption.action)
                  : null}
                &nbsp;
                <span className={styles.link}>{crisisOption.linkText}</span>
              </p>
            </div>
          </Card.Body>
        </Card>
      </a>
    </Col>
  );
};

interface CrisisOptionData {
  index: number;
  icon: Image;
  target: string;
  action: RichTextField;
  linkText: string;
  linkUrl: string;
  trackingLabel: PersonType;
}

interface CrisisPageData {
  node_locale: NodeLocale;
  pageTitle: string;
  header: string;
  subTitle: {
    subTitle: string;
  };
  pageSummary: RichTextField;
  crisisOptionsHeader: string;
  crisisOptions: CrisisOptionData[];
  crisisOptionsExtraHeader: string;
  crisisOptionsExtra: CrisisOptionData[];
  alert: {
    id: string;
    alert: string;
  };
}

export const query = graphql`
  fragment CrisisOptionFragment on ContentfulCrisisOption {
    index
    icon {
      title
      gatsbyImageData(
        width: 79
        layout: FIXED
        placeholder: BLURRED
        formats: [AUTO, WEBP]
      )
    }
    target
    action {
      raw
    }
    linkText
    linkUrl
    trackingLabel
  }

  query($node_locale: String!) {
    contentfulCrisisPage(node_locale: { eq: $node_locale }) {
      node_locale
      pageTitle
      header
      subTitle {
        subTitle
      }
      pageSummary {
        raw
      }
      crisisOptionsHeader
      crisisOptions {
        ...CrisisOptionFragment
      }
      crisisOptionsExtraHeader
      crisisOptionsExtra {
        ...CrisisOptionFragment
      }
      alert {
        id
        alert
      }
    }
  }
`;
