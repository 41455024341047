// extracted by mini-css-extract-plugin
export const actionHeader = "crisis-module--actionHeader--3gqTZ";
export const pageSummary = "crisis-module--pageSummary--1bKMM";
export const crisisCardCol = "crisis-module--crisisCardCol--2xlbo";
export const crisisCardWrapper = "crisis-module--crisisCardWrapper--3xSj0";
export const link = "crisis-module--link--3a8s2";
export const crisisHeader = "crisis-module--crisisHeader--2V96u";
export const crisisCard = "crisis-module--crisisCard--3UGyy";
export const crisisCardHeader = "crisis-module--crisisCardHeader--2M3k3";
export const placeholderIcon = "crisis-module--placeholderIcon--iwHPA";
export const crisisCardContent = "crisis-module--crisisCardContent--3-Ldx";
export const icon = "crisis-module--icon--2jzVa";
export const crisisCardCopy = "crisis-module--crisisCardCopy--3VOAF";
export const hopeForWellness = "crisis-module--hopeForWellness--1tlcS";
export const landingWrapper = "crisis-module--landingWrapper--27H13";
export const header = "crisis-module--header--L6lyD";
export const copy = "crisis-module--copy--3Y-8r";
export const contentWrapper = "crisis-module--contentWrapper--1I2n-";
export const supportHeader = "crisis-module--supportHeader--qKMfB";
export const tile = "crisis-module--tile--3fmBg";
export const text = "crisis-module--text--1F0FL";
export const action = "crisis-module--action--SebDp";